import { TLinks } from 'templates/layout/types';

export const SERVICE_LINK_LIST: TLinks[] = [
  {
    label: 'Bespoke Software',
    route: '/bespoke-software',
    transparentNavbar: true,
  },
  {
    label: 'Agile Prototyping',
    route: '/agile-prototyping',
    transparentNavbar: true,
  },
  {
    label: 'Managed IT Services',
    route: '/managed-it-services',
    transparentNavbar: true,
  },
  {
    label: 'Consultancy',
    route: '/consultancy',
    transparentNavbar: true,
  },
  {
    label: 'IT Infrastructure Services',
    route: '/it-infrastructure',
    transparentNavbar: true,
  },
  {
    label: 'Branding & Design',
    route: '/branding-design',
    transparentNavbar: true,
  },
  {
    label: 'Digital Marketing & SEO',
    route: '/digital-marketing',
    transparentNavbar: true,
  },
];

export const ABOUT_LINKS: TLinks[] = [
  {
    label: 'Cookie policy',
    route: '/cookie',
  },
  {
    label: 'Privacy policy',
    route: '/privacy',
  },
  {
    label: 'Terms of use',
    route: '/terms',
  },
];

export const GENERAL_LINKS: TLinks[] = [
  {
    label: 'Home',
    route: '/',
  },
  {
    label: 'Company',
    route: '/company',
  },
  {
    label: 'Blog',
    route: '/blog',
  },
  {
    label: 'Careers',
    route: '/careers',
  },
  {
    label: 'Contact Us',
    route: '/contact-us',
  },
];

export const PRODUCT_LIST_UTILITIES: TLinks[] = [
  {
    label: 'Utilities One',
    route: '/utilities-one',
  },
  {
    label: 'Patron Trucking',
    route: '/patron-trucking',
  },
];

export const PRODUCT_LIST_LOGISTIC: TLinks[] = [
  {
    label: 'EasiConnect',
    route: '/easiConnect',
  },
  {
    label: 'LED4Free',
    route: '/led4free',
    transparentNavbar: true,
  },
  {
    label: 'GPM',
    route: '/gpm',
  },
];

export const PRODUCTS_LINKS: TLinks[] = [...PRODUCT_LIST_UTILITIES, ...PRODUCT_LIST_LOGISTIC];

export const ALL_ROUTES: TLinks[] = [
  ...SERVICE_LINK_LIST,
  ...PRODUCT_LIST_UTILITIES,
  ...PRODUCT_LIST_LOGISTIC,
  ...ABOUT_LINKS,
  ...GENERAL_LINKS,
];
